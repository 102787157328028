import { useTranslation } from 'react-i18next';
import {
  SubformComponent,
  SubCardFieldsComponent,
  SubModule,
} from '@/modules/admin/audits/components/submodules/types';
import { LineText } from '@common/components/fragments/LineText';
import AuditsExportsEmploymentContractsExport from '@spraypaint/audits/exports/AuditsExportsEmploymentContractsExport';

export const jsonApiType = AuditsExportsEmploymentContractsExport.jsonapiType;

export type Values = {};

export const defaultValues: Values = {
  // Today, iso8601 format date
  year: new Date().getFullYear(),
};

function createExportStrategy(): AuditsExportsEmploymentContractsExport {
  return new AuditsExportsEmploymentContractsExport();
}

export const FormComponent: SubformComponent = () => null;

export const CardFieldsComponent: SubCardFieldsComponent<
  AuditsExportsEmploymentContractsExport
> = () => {
  const { t } = useTranslation();

  return (
    <LineText
      label={t('activerecord.attributes.audits/export.export_strategy', {
        ns: 'rails',
      })}
      value={t('activerecord.models.audits/exports/provision_export', {
        ns: 'rails',
      })}
    />
  );
};

export const AuditsExportsEmploymentContractsExportSubmodule: SubModule<
  Values,
  AuditsExportsEmploymentContractsExport
> = {
  jsonApiType,
  defaultValues,
  FormComponent,
  CardFieldsComponent,
  createExportStrategy,
};
