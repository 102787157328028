// api/AuditsExportsEmploymentContractsExport.ts
import { Model, HasOne } from 'spraypaint';
import ApplicationRecord from '../../ApplicationRecord';
import type AuditsExport from '../AuditsExport';

@Model()
class AuditsExportsEmploymentContractsExport extends ApplicationRecord {
  static jsonapiType = 'audits-exports-employment_contracts_exports';

  // Attributes

  // Extra-attributes

  // Relationships
  @HasOne('audits-exports') export!: AuditsExport;
}

export default AuditsExportsEmploymentContractsExport;
