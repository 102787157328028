import { useTranslation } from 'react-i18next';
import {
  SubformComponent,
  SubCardFieldsComponent,
  SubModule,
} from '@/modules/admin/audits/components/submodules/types';
import { LineText } from '@common/components/fragments/LineText';
import AuditsExportsMonthlyTurnoverExport from '@spraypaint/audits/exports/AuditsExportsMonthlyTurnoverExport';
import { ControlledNumberInput } from '@common/components/inputs/controlledInput/ControlledNumberInput';

export const jsonApiType = AuditsExportsMonthlyTurnoverExport.jsonapiType;

export type Values = {
  year: AuditsExportsMonthlyTurnoverExport['year'];
};

export const defaultValues: Values = {
  // Today, iso8601 format date
  year: new Date().getFullYear(),
};

function createExportStrategy(
  data: Values,
): AuditsExportsMonthlyTurnoverExport {
  return new AuditsExportsMonthlyTurnoverExport({
    year: data.year,
  });
}

export const FormComponent: SubformComponent = ({ control }) => {
  const { t } = useTranslation();
  return (
    <ControlledNumberInput
      label={t(
        'activerecord.attributes.audits/exports/monthly_turnover_export.year',
        {
          ns: 'rails',
        },
      )}
      control={control}
      name="exportStrategy.params.year"
      rules={{ valueAsNumber: true, required: true }}
      colDisplay
      currencyIsoCode={null}
      required
      className="flex items-center gap-4"
    />
  );
};

export const CardFieldsComponent: SubCardFieldsComponent<
  AuditsExportsMonthlyTurnoverExport
> = ({ strategy }) => {
  const { t } = useTranslation();

  return (
    <>
      <LineText
        label={t('activerecord.attributes.audits/export.export_strategy', {
          ns: 'rails',
        })}
        value={t('activerecord.models.audits/exports/monthly_turnover_export', {
          ns: 'rails',
        })}
      />{' '}
      <LineText
        label={t(
          'activerecord.attributes.audits/exports/monthly_turnover_export.year',
          {
            ns: 'rails',
          },
        )}
        value={strategy.year}
      />
    </>
  );
};

export const AuditsExportsMonthlyTurnoverExportSubmodule: SubModule<
  Values,
  AuditsExportsMonthlyTurnoverExport
> = {
  jsonApiType,
  defaultValues,
  FormComponent,
  CardFieldsComponent,
  createExportStrategy,
};
