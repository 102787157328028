import { Model, Attr, HasOne } from 'spraypaint';
import ApplicationRecord from '../../ApplicationRecord';
import type AuditsExport from '../AuditsExport';

@Model()
class AuditsExportsMarginsExport extends ApplicationRecord {
  static jsonapiType = 'audits-exports-margins_exports' as const;

  // Attributes
  @Attr() year!: number;

  // Extra-attributes

  // Relationships
  @HasOne('audits-exports') export!: AuditsExport;
}

export default AuditsExportsMarginsExport;
